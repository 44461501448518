import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _3b66da10 = () => interopDefault(import('../pages/accessibilite.vue' /* webpackChunkName: "pages/accessibilite" */))
const _0bc33e35 = () => interopDefault(import('../pages/bureau-etude-urbanisme.vue' /* webpackChunkName: "pages/bureau-etude-urbanisme" */))
const _3b464bdc = () => interopDefault(import('../pages/collectivites-territoriales.vue' /* webpackChunkName: "pages/collectivites-territoriales" */))
const _04bfb130 = () => interopDefault(import('../pages/confidentialite.vue' /* webpackChunkName: "pages/confidentialite" */))
const _4dd6bb41 = () => interopDefault(import('../pages/ddt-ddtm-dreal.vue' /* webpackChunkName: "pages/ddt-ddtm-dreal" */))
const _7ac0b815 = () => interopDefault(import('../pages/exports.vue' /* webpackChunkName: "pages/exports" */))
const _33167e5c = () => interopDefault(import('../pages/guide.vue' /* webpackChunkName: "pages/guide" */))
const _6020b202 = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _0b48c423 = () => interopDefault(import('../pages/loi-climat-et-resilience.vue' /* webpackChunkName: "pages/loi-climat-et-resilience" */))
const _3ecb743d = () => interopDefault(import('../pages/mentions-legales.vue' /* webpackChunkName: "pages/mentions-legales" */))
const _0128997f = () => interopDefault(import('../pages/profile.vue' /* webpackChunkName: "pages/profile" */))
const _37060890 = () => interopDefault(import('../pages/stats/index.vue' /* webpackChunkName: "pages/stats/index" */))
const _062c7c13 = () => interopDefault(import('../pages/validation.vue' /* webpackChunkName: "pages/validation" */))
const _2e44f464 = () => interopDefault(import('../pages/ddt/enquete_overview.vue' /* webpackChunkName: "pages/ddt/enquete_overview" */))
const _89e47514 = () => interopDefault(import('../pages/dev/api.vue' /* webpackChunkName: "pages/dev/api" */))
const _cf6ac012 = () => interopDefault(import('../pages/stats/state.vue' /* webpackChunkName: "pages/stats/state" */))
const _a4fd9db4 = () => interopDefault(import('../pages/stats/urba.vue' /* webpackChunkName: "pages/stats/urba" */))
const _28f088f0 = () => interopDefault(import('../pages/login/collectivites/explain.vue' /* webpackChunkName: "pages/login/collectivites/explain" */))
const _6b8796a1 = () => interopDefault(import('../pages/login/collectivites/signin.vue' /* webpackChunkName: "pages/login/collectivites/signin" */))
const _ffc425d2 = () => interopDefault(import('../pages/login/collectivites/signup.vue' /* webpackChunkName: "pages/login/collectivites/signup" */))
const _5159340c = () => interopDefault(import('../pages/login/ddt/explain.vue' /* webpackChunkName: "pages/login/ddt/explain" */))
const _8dba04c6 = () => interopDefault(import('../pages/login/ddt/signin.vue' /* webpackChunkName: "pages/login/ddt/signin" */))
const _648d57da = () => interopDefault(import('../pages/login/ddt/signup.vue' /* webpackChunkName: "pages/login/ddt/signup" */))
const _3216d3a8 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _0d3ade34 = () => interopDefault(import('../pages/collectivites/_collectiviteId.vue' /* webpackChunkName: "pages/collectivites/_collectiviteId" */))
const _33acedb7 = () => interopDefault(import('../pages/collectivites/_collectiviteId/index.vue' /* webpackChunkName: "pages/collectivites/_collectiviteId/index" */))
const _2be9c1e6 = () => interopDefault(import('../pages/collectivites/_collectiviteId/donnees.vue' /* webpackChunkName: "pages/collectivites/_collectiviteId/donnees" */))
const _368f99c8 = () => interopDefault(import('../pages/collectivites/_collectiviteId/donnees/data.vue' /* webpackChunkName: "pages/collectivites/_collectiviteId/donnees/data" */))
const _3317f92b = () => interopDefault(import('../pages/collectivites/_collectiviteId/donnees/geobretagne.vue' /* webpackChunkName: "pages/collectivites/_collectiviteId/donnees/geobretagne" */))
const _7f6fe1aa = () => interopDefault(import('../pages/collectivites/_collectiviteId/donnees/geoide.vue' /* webpackChunkName: "pages/collectivites/_collectiviteId/donnees/geoide" */))
const _2fd506ef = () => interopDefault(import('../pages/collectivites/_collectiviteId/donnees/georisques.vue' /* webpackChunkName: "pages/collectivites/_collectiviteId/donnees/georisques" */))
const _2e2317ac = () => interopDefault(import('../pages/collectivites/_collectiviteId/donnees/gpu.vue' /* webpackChunkName: "pages/collectivites/_collectiviteId/donnees/gpu" */))
const _d49a3656 = () => interopDefault(import('../pages/collectivites/_collectiviteId/donnees/inpn.vue' /* webpackChunkName: "pages/collectivites/_collectiviteId/donnees/inpn" */))
const _fe86a76c = () => interopDefault(import('../pages/collectivites/_collectiviteId/glossaire.vue' /* webpackChunkName: "pages/collectivites/_collectiviteId/glossaire" */))
const _23ce04b7 = () => interopDefault(import('../pages/collectivites/_collectiviteId/pac.vue' /* webpackChunkName: "pages/collectivites/_collectiviteId/pac" */))
const _dddd47fe = () => interopDefault(import('../pages/collectivites/_collectiviteId/prescriptions/index.vue' /* webpackChunkName: "pages/collectivites/_collectiviteId/prescriptions/index" */))
const _39dd9283 = () => interopDefault(import('../pages/collectivites/_collectiviteId/ressources.vue' /* webpackChunkName: "pages/collectivites/_collectiviteId/ressources" */))
const _7246b3e0 = () => interopDefault(import('../pages/collectivites/_collectiviteId/prescriptions/add.vue' /* webpackChunkName: "pages/collectivites/_collectiviteId/prescriptions/add" */))
const _48c0d0f9 = () => interopDefault(import('../pages/collectivites/_collectiviteId/prescriptions/signup.vue' /* webpackChunkName: "pages/collectivites/_collectiviteId/prescriptions/signup" */))
const _cac8ba06 = () => interopDefault(import('../pages/collectivites/_collectiviteId/procedures/add.vue' /* webpackChunkName: "pages/collectivites/_collectiviteId/procedures/add" */))
const _72805565 = () => interopDefault(import('../pages/ddt/_departement.vue' /* webpackChunkName: "pages/ddt/_departement" */))
const _126307b0 = () => interopDefault(import('../pages/ddt/_departement/index.vue' /* webpackChunkName: "pages/ddt/_departement/index" */))
const _4a0753c9 = () => interopDefault(import('../pages/ddt/_departement/collectivites/index.vue' /* webpackChunkName: "pages/ddt/_departement/collectivites/index" */))
const _239b0930 = () => interopDefault(import('../pages/ddt/_departement/pac.vue' /* webpackChunkName: "pages/ddt/_departement/pac" */))
const _0e68829d = () => interopDefault(import('../pages/ddt/_departement/procedures/index.vue' /* webpackChunkName: "pages/ddt/_departement/procedures/index" */))
const _cd6895c8 = () => interopDefault(import('../pages/ddt/_departement/procedures/choix-collectivite.vue' /* webpackChunkName: "pages/ddt/_departement/procedures/choix-collectivite" */))
const _5df08185 = () => interopDefault(import('../pages/ddt/_departement/collectivites/_collectiviteId/commune.vue' /* webpackChunkName: "pages/ddt/_departement/collectivites/_collectiviteId/commune" */))
const _0d80fafc = () => interopDefault(import('../pages/ddt/_departement/collectivites/_collectiviteId/epci.vue' /* webpackChunkName: "pages/ddt/_departement/collectivites/_collectiviteId/epci" */))
const _3e04b36c = () => interopDefault(import('../pages/ddt/_departement/collectivites/_collectiviteId/procedure/add.vue' /* webpackChunkName: "pages/ddt/_departement/collectivites/_collectiviteId/procedure/add" */))
const _bf479c68 = () => interopDefault(import('../pages/frise/_procedureId/index.vue' /* webpackChunkName: "pages/frise/_procedureId/index" */))
const _d796ef5e = () => interopDefault(import('../pages/print/_projectId.vue' /* webpackChunkName: "pages/print/_projectId" */))
const _eaf79fc4 = () => interopDefault(import('../pages/trames/_githubRef.vue' /* webpackChunkName: "pages/trames/_githubRef" */))
const _607e75f6 = () => interopDefault(import('../pages/documents/_githubRef/pac.vue' /* webpackChunkName: "pages/documents/_githubRef/pac" */))
const _69e8729b = () => interopDefault(import('../pages/frise/_procedureId/add.vue' /* webpackChunkName: "pages/frise/_procedureId/add" */))
const _eea9dc02 = () => interopDefault(import('../pages/frise/_procedureId/invite.vue' /* webpackChunkName: "pages/frise/_procedureId/invite" */))
const _074db90c = () => interopDefault(import('../pages/frise/_procedureId/_eventId.vue' /* webpackChunkName: "pages/frise/_procedureId/_eventId" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/accessibilite",
    component: _3b66da10,
    name: "accessibilite"
  }, {
    path: "/bureau-etude-urbanisme",
    component: _0bc33e35,
    name: "bureau-etude-urbanisme"
  }, {
    path: "/collectivites-territoriales",
    component: _3b464bdc,
    name: "collectivites-territoriales"
  }, {
    path: "/confidentialite",
    component: _04bfb130,
    name: "confidentialite"
  }, {
    path: "/ddt-ddtm-dreal",
    component: _4dd6bb41,
    name: "ddt-ddtm-dreal"
  }, {
    path: "/exports",
    component: _7ac0b815,
    name: "exports"
  }, {
    path: "/guide",
    component: _33167e5c,
    name: "guide"
  }, {
    path: "/login",
    component: _6020b202,
    name: "login"
  }, {
    path: "/loi-climat-et-resilience",
    component: _0b48c423,
    name: "loi-climat-et-resilience"
  }, {
    path: "/mentions-legales",
    component: _3ecb743d,
    name: "mentions-legales"
  }, {
    path: "/profile",
    component: _0128997f,
    name: "profile"
  }, {
    path: "/stats",
    component: _37060890,
    name: "stats"
  }, {
    path: "/validation",
    component: _062c7c13,
    name: "validation"
  }, {
    path: "/ddt/enquete_overview",
    component: _2e44f464,
    name: "ddt-enquete_overview"
  }, {
    path: "/dev/api",
    component: _89e47514,
    name: "dev-api"
  }, {
    path: "/stats/state",
    component: _cf6ac012,
    name: "stats-state"
  }, {
    path: "/stats/urba",
    component: _a4fd9db4,
    name: "stats-urba"
  }, {
    path: "/login/collectivites/explain",
    component: _28f088f0,
    name: "login-collectivites-explain"
  }, {
    path: "/login/collectivites/signin",
    component: _6b8796a1,
    name: "login-collectivites-signin"
  }, {
    path: "/login/collectivites/signup",
    component: _ffc425d2,
    name: "login-collectivites-signup"
  }, {
    path: "/login/ddt/explain",
    component: _5159340c,
    name: "login-ddt-explain"
  }, {
    path: "/login/ddt/signin",
    component: _8dba04c6,
    name: "login-ddt-signin"
  }, {
    path: "/login/ddt/signup",
    component: _648d57da,
    name: "login-ddt-signup"
  }, {
    path: "/",
    component: _3216d3a8,
    name: "index"
  }, {
    path: "/collectivites/:collectiviteId?",
    component: _0d3ade34,
    children: [{
      path: "",
      component: _33acedb7,
      name: "collectivites-collectiviteId"
    }, {
      path: "donnees",
      component: _2be9c1e6,
      name: "collectivites-collectiviteId-donnees",
      children: [{
        path: "data",
        component: _368f99c8,
        name: "collectivites-collectiviteId-donnees-data"
      }, {
        path: "geobretagne",
        component: _3317f92b,
        name: "collectivites-collectiviteId-donnees-geobretagne"
      }, {
        path: "geoide",
        component: _7f6fe1aa,
        name: "collectivites-collectiviteId-donnees-geoide"
      }, {
        path: "georisques",
        component: _2fd506ef,
        name: "collectivites-collectiviteId-donnees-georisques"
      }, {
        path: "gpu",
        component: _2e2317ac,
        name: "collectivites-collectiviteId-donnees-gpu"
      }, {
        path: "inpn",
        component: _d49a3656,
        name: "collectivites-collectiviteId-donnees-inpn"
      }]
    }, {
      path: "glossaire",
      component: _fe86a76c,
      name: "collectivites-collectiviteId-glossaire"
    }, {
      path: "pac",
      component: _23ce04b7,
      name: "collectivites-collectiviteId-pac"
    }, {
      path: "prescriptions",
      component: _dddd47fe,
      name: "collectivites-collectiviteId-prescriptions"
    }, {
      path: "ressources",
      component: _39dd9283,
      name: "collectivites-collectiviteId-ressources"
    }, {
      path: "prescriptions/add",
      component: _7246b3e0,
      name: "collectivites-collectiviteId-prescriptions-add"
    }, {
      path: "prescriptions/signup",
      component: _48c0d0f9,
      name: "collectivites-collectiviteId-prescriptions-signup"
    }, {
      path: "procedures/add",
      component: _cac8ba06,
      name: "collectivites-collectiviteId-procedures-add"
    }]
  }, {
    path: "/ddt/:departement?",
    component: _72805565,
    children: [{
      path: "",
      component: _126307b0,
      name: "ddt-departement"
    }, {
      path: "collectivites",
      component: _4a0753c9,
      name: "ddt-departement-collectivites"
    }, {
      path: "pac",
      component: _239b0930,
      name: "ddt-departement-pac"
    }, {
      path: "procedures",
      component: _0e68829d,
      name: "ddt-departement-procedures"
    }, {
      path: "procedures/choix-collectivite",
      component: _cd6895c8,
      name: "ddt-departement-procedures-choix-collectivite"
    }, {
      path: "collectivites/:collectiviteId/commune",
      component: _5df08185,
      name: "ddt-departement-collectivites-collectiviteId-commune"
    }, {
      path: "collectivites/:collectiviteId/epci",
      component: _0d80fafc,
      name: "ddt-departement-collectivites-collectiviteId-epci"
    }, {
      path: "collectivites/:collectiviteId/procedure/add",
      component: _3e04b36c,
      name: "ddt-departement-collectivites-collectiviteId-procedure-add"
    }]
  }, {
    path: "/frise/:procedureId",
    component: _bf479c68,
    name: "frise-procedureId"
  }, {
    path: "/print/:projectId?",
    component: _d796ef5e,
    name: "print-projectId"
  }, {
    path: "/trames/:githubRef?",
    component: _eaf79fc4,
    name: "trames-githubRef"
  }, {
    path: "/documents/:githubRef?/pac",
    component: _607e75f6,
    name: "documents-githubRef-pac"
  }, {
    path: "/frise/:procedureId?/add",
    component: _69e8729b,
    name: "frise-procedureId-add"
  }, {
    path: "/frise/:procedureId?/invite",
    component: _eea9dc02,
    name: "frise-procedureId-invite"
  }, {
    path: "/frise/:procedureId?/:eventId",
    component: _074db90c,
    name: "frise-procedureId-eventId"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
