import { render, staticRenderFns } from "./Footer.vue?vue&type=template&id=093d23ea&scoped=true"
var script = {}
import style0 from "./Footer.vue?vue&type=style&index=0&id=093d23ea&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "093d23ea",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LandingNewsLetterForm: require('/build/410d3539-8d80-49d8-b593-adf9ce3c37c6/nuxt/components/Landing/NewsLetterForm.vue').default})


/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VContainer,VFooter,VImg,VRow})
